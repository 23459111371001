<template>
  <div>
    <Top />
    <v-container style="max-width: 900px; padding-bottom: 69px" class="mt-2">
      <v-row>
        <v-col
          cols="4"
          class="text-left d-none d-sm-block"
          style="height: calc(90vh - 69px); border: solid 1px #ccc"
        >
          <v-list flat>
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item v-for="(item, i) in items" :key="i" @click="goTo(item.action)" >
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col
          cols="12"
          sm="8"
          class="text-left"
          style="height: calc(90vh - 69px); border: solid 1px #ccc; border-left: none;     overflow: auto;"
        >
         
          <account-profile :data_user="data_user" v-if="account == 'profile'" />
          <account-password :data_user="data_user" v-if="account == 'password'" />
          <account-privacy-and-security  v-if="account == 'privacy_and_security'"/>
          
        </v-col>
      </v-row>
    </v-container>

     <bottom-navigation></bottom-navigation>
  </div>
  
</template>
<script>
export default {
  data() {
    return {
      avatar: require("@/assets/avatar.jpg"),
      post: require("@/assets/post.jpg"),
      list: [],
      selectedItem: 1,
      items: [
        
        { text: "Edit profile", action: "profile" },
        { text: "Change Password", action: "password" },
        { text: "Privacy and security", action: "privacy_and_security" },
      ],
      account: "profile",
      data_user:{
        avatar: require("@/assets/avatar.jpg"),
        username: "eduardosantana5295"
      }
    };
  },
  methods: {
    goTo(action) {
      this.account = action;
    },

  },
};
</script>